<template>
  <footer class="mt-5 lg:mt-0">
    <div class="xl:container py-3">
      <p>
        &copy; {{ new Date().getFullYear() }} -
        <a href="https://jelleharmen.com" target="_blank">JelleHarmen.com</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style></style>

<template>
  <header>
    <div class="xl:container">
      <div class="bg-blue-50 rounded-xl p-6 pb-8">
        <h1 class="text-4xl font-bold py-4">Design Configurator</h1>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped></style>

<template>
  <div
    class="bg-cover relative top-0 left-0 w-full bg-gray-100 shadow-md lg:shadow-md lg:rounded-r-2xl overflow-hidden"
  >
    <div
      class="absolute left-0 top-0 bg-cover w-full h-full pointer-events-none fadeIn bg-bottom"
      :key="product.id"
      v-for="product in products"
      :style="{ 'background-image': product.background }"
    ></div>

    <img
      src="@/assets/spinner.svg"
      alt="Spinner, Loading.."
      class="absolute right-3 top-3 w-12 pointer-events-none"
      v-show="loading"
    />
  </div>
</template>

<script>
import CompositionEngine from "@/use/CompositionEngine";

export default {
  name: "ProductSelector",
  setup() {
    const { company, products, loading, side } = CompositionEngine();

    return {
      company,
      products,
      loading,
      side,
    };
  },
};
</script>

<style lang="scss" scoped></style>

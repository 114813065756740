<template>
  <section
    class="flex flex-col h-full min-h-screen lg:justify-between md:absolute top-0 left-0 md:w-full"
  >
    <section>
      <div class="xl:container py-3 xl:py-6">
        <div class="grid grid-cols-3">
          <router-link :to="{ name: 'Home' }">
            <img
              src="@/assets/logo.svg"
              alt="Design logo"
              title="Design logo"
              class="logo"
          /></router-link>
        </div>
      </div>
    </section>

    <div class="lg:flex h-full overflow-y-scroll xl:pb-4">
      <CompositionBackground class="h-64 lg:h-full" />
      <ProductSelector class="lg:w-192  h-full lg:overflow-y-scroll w-full" />
    </div>

    <Footer />
  </section>
</template>

<script>
import Footer from "@/components/layout/Footer";
import CompositionBackground from "@/components/composition/CompositionBackground";
import ProductSelector from "@/components/composition/ProductSelector";

import companies from "@/config/companies";

// import ReadMore from "@/components/helpers/ReadMore";
// import Modal from "@/components/helpers/Modal";

import CompositionEngine from "@/use/CompositionEngine";

export default {
  name: "Configurator",
  data() {
    return {
      companies,
    };
  },
  setup() {
    const { company, productAdd } = CompositionEngine();
    return {
      company,
      productAdd,
    };
  },
  components: {
    Footer,
    CompositionBackground,
    ProductSelector,
  },
  mounted() {
    this.company = this.companies[0];

    this.productAdd(this.companies[0].products[0], 0);
  },
};
</script>

export default [
  {
    id: 0,
    name: "Designcompany",
    logo: `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa/sofa-grey.jpg")})`,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vulputate nunc eu leo convallis placerat. Cras in faucibus ligula. Nullam porta luctus metus, at ornare est imperdiet eget. Vestibulum hendrerit, magna in pharetra convallis, purus nunc semper tellus, nec suscipit nisl ex quis massa. Ut vestibulum risus arcu. Phasellus varius blandit enim ac rhoncus.",
    settings: {
      animation_remove: false,
    },
    products: [
      {
        id: 0,
        name: "Couch",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere quia magnam beatae fugit doloremque asperiores, saepe fugiat vitae dolor odio nulla, architecto obcaecati delectus dignissimos illum id molestias consequuntur repudiandae!",
        order: 0,
        poi: { left: 83, top: 83 },
        sides: 2,
        variations: [
          {
            id: 0,
            name: "Blue",
            thumbnail: `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa-material/blue.jpg")})`,
            image_urls: [
              `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa/sofa-blue.jpg")})`,
              `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa/sofa-blue-left.jpg")})`,
            ],
          },
          {
            id: 1,
            name: "Beige",
            thumbnail: `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa-material/beige.jpg")})`,
            image_urls: [
              `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa/sofa-beige.jpg")})`,
              `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa/sofa-beige.jpg")})`,
            ],
          },
          {
            id: 2,
            name: "Grey",
            thumbnail: `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa-material/grey.jpg")})`,
            image_urls: [
              `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa/sofa-grey.jpg")})`,
              `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa/sofa-grey.jpg")})`,
            ],
          },
          {
            id: 3,
            name: "Yellow",
            thumbnail: `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa-material/yellow.jpg")})`,
            image_urls: [
              `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa/sofa-yellow.jpg")})`,
              `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa/sofa-yellow.jpg")})`,
            ],
          },
          {
            id: 4,
            name: "Dark green",
            thumbnail: `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa-material/dark-green.jpg")})`,
            image_urls: [
              `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa/sofa-dark-green.jpg")})`,
              `url(${require("../assets/compositions/ontwerpbureau/room-1/sofa/sofa-dark-green.jpg")})`,
            ],
          },
        ],
      },
    ],
  },
];

<template>
  <section>
    <div class="xl:container">
      <div class="md:bg-white py-3 md:p-3 rounded-xl">
        <div class="company-information">
          <h5 class="font-bold text-2xl">{{ company.name }}</h5>

          <ReadMore :text="company.description" />
        </div>
        <div class="line"></div>

        <div :key="product.id" v-for="product in company.products">
          <div class="pb-4">
            <h5 class="font-bold text-xl">{{ product.name }}</h5>

            <ReadMore :text="product.description" maxChar="48" />
          </div>

          <div class="pb-6">
            <p class="font-semibold pb-2">Choose materials:</p>
            <button
              :key="variation.id"
              v-for="(variation, index) in product.variations"
              @click="productAdd(product, index)"
              :style="{ 'background-image': variation.thumbnail }"
              :title="variation.name"
              class="p-5 rounded-full mr-2 relative shadow-sm"
              :class="{
                'disabled pointer-events-none': loading,
              }"
            >
              <div
                v-show="productsUsed().includes(variation.id)"
                class="absolute top-0 left-0 h-full w-full rounded-full border-2 border-green-400 fadeIn shadow-md"
              ></div>
            </button>

            <div class="pt-2">
              <button
                @click="changePerspective()"
                class="text-primary underline"
              >
                Change perspective
              </button>
            </div>
          </div>

          <div>
            <a
              class="btn bg-primary"
              target="_blank"
              href="https://jelleharmen.com/"
            >
              Order {{ product.name }} Now
              <img
                src="@/assets/fontawesome/regular/external-link.svg"
                alt="External link"
                class="invert fa"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CompositionEngine from "@/use/CompositionEngine";

import ReadMore from "@/components/helpers/ReadMore";
export default {
  name: "ProductSelector",
  components: { ReadMore },
  setup() {
    const {
      company,
      productAdd,
      loading,
      products,
      side,
      changePerspective,
      productsUsed,
    } = CompositionEngine();

    return {
      company,
      productAdd,
      loading,
      products,
      side,
      changePerspective,
      productsUsed,
    };
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <section>
    <div class="xl:container">
      <h1 class="text-4xl mb-3">Pagina helaas niet gevonden.</h1>
      <router-link to="/" class="btn bg-primary">Terug naar home</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style></style>

<template>
  <section>
    <div class="xl:container py-3">
      <div class="grid grid-cols-3">
        <router-link :to="{ name: 'Home' }" class="">
          <img
            src="@/assets/logo.svg"
            alt="Design logo"
            title="Design logo"
            class="logo"
        /></router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Nav",
};
</script>

<style lang="scss" scoped>
.logo {
  max-height: 55px;
}
</style>

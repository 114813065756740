<template>
  <p class="inline">
    {{ theText }}<span v-show="!expanded">&hellip;</span>&nbsp;
  </p>

  <button class="text-primary" @click="expanded = !expanded">
    <span v-show="!expanded">Read more</span>
    <span v-show="expanded">Read less</span>
  </button>
</template>

<script>
export default {
  name: "ReadMore",
  data() {
    return {
      expanded: false,
    };
  },
  props: {
    text: {
      type: String,
      default: "There is no text.",
    },
    maxChar: {
      type: String,
      default: "123",
    },
  },
  computed: {
    theText() {
      if (this.expanded) return this.text;
      return this.text.substring(0, this.maxChar);
    },
  },
};
</script>

<style></style>

<template>
  <div class="flex flex-col justify-between min-h-screen">
    <div>
      <Nav />
      <router-view />
    </div>
    <Footer :dark="true" />
  </div>
</template>

<script>
import Nav from "@/components/layout/Nav";
import Footer from "@/components/layout/Footer";
export default {
  name: "WebsiteLayout",
  components: { Nav, Footer },
};
</script>

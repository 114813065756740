import { createRouter, createWebHistory } from "vue-router";
import WebsiteLayout from "../views/layouts/WebsiteLayout.vue";
import Configurator from "../views/layouts/Configurator.vue";

import Home from "../views/Home.vue";
import PageNotFound from "../views/PageNotFound.vue";

const routes = [
  {
    path: "/",
    component: WebsiteLayout,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "/:catchAll(.*)",
        name: "PageNotFound",
        component: PageNotFound,
      },
    ],
  },

  {
    path: "/configurator/:company_name/",
    name: "Configurator",
    component: Configurator,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;

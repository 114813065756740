import { ref } from "vue";

const company = ref([]);
const products = ref([]);
const loading = ref(false);
const side = ref(0);
const product_ids = ref([]);

export default function() {
  // loading start
  const loadingStart = () => {
    loading.value = true;
  };

  // loading stop
  const loadingStopTimer = (nr) => {
    if (!nr) nr = 800;
    setTimeout(() => {
      loading.value = false;
    }, nr);
  };

  const productPush = (product) => {
    if (product) {
      const newProduct = JSON.parse(JSON.stringify(product));
      newProduct.background = newProduct.image_urls[side.value];

      products.value.push(newProduct);
    }
  };

  const productSplice = (product) => {
    setTimeout(() => {
      const nr = products.value.indexOf(product);
      products.value.splice(nr, 1);
    }, 800);
  };

  const productAdd = (product, index) => {
    if (!loading.value) {
      loadingStart();

      // console.log(product.variations[index]);
      product.variations[index].id_product = product.id;

      const findProductCategoryDouble = products.value.some((p) => {
        return p.id_product == product.id;
      });

      const productExists = products.value.some((p) => {
        return p.id == product.variations[index].id;
      });

      // nothing happend
      if (productExists) {
        loadingStopTimer();

        // if double is found
      } else if (findProductCategoryDouble) {
        const key = products.value.filter((p) => {
          return p.id_product == product.id;
        });

        // remove the product
        productSplice(key[0]);

        // replace product with
        productPush(product.variations[index]);
        console.log("replaced");

        loadingStopTimer();
      } else {
        // new product
        productPush(product.variations[index]);

        loadingStopTimer();
      }
    }
  };

  const changePerspective = () => {
    if (!loading.value) {
      loadingStart();
      side.value = side.value == 1 ? 0 : 1;

      productPush(products.value[0]);
      productSplice(products.value[0]);
      loadingStopTimer();
    }
  };

  const productsUsed = () => {
    return products.value.map((product) => {
      return product.id;
    });
  };

  return {
    company,
    productAdd,
    products,
    loading,
    side,
    changePerspective,
    product_ids,
    productsUsed,
  };
}

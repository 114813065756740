<template>
  <Header />

  <section>
    <div class="xl:container">
      <div class="px-6 -mt-5 capitalize">
        <router-link
          :key="name"
          :to="{
            name: 'Configurator',
            params: { company_name: name.toLowerCase() },
          }"
          v-for="{ name } in companies"
          class="btn bg-primary text-white mr-2"
        >
          {{ name }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "@/components/layout/Header";

import companies from "@/config/companies";

export default {
  name: "Home",
  components: { Header },
  data() {
    return {
      companies,
    };
  },
};
</script>
